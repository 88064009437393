import {GetUserInfoApi} from '@/request/api'
export default{
    namespaced:true,
    state:{
        //用户信息，localStorage做本地存储
        userInfo:JSON.parse(localStorage.getItem("userInfo")) || {
            user:null,
            roles:null
        }
    },
    mutations:{
        changeUserInfo(state,payload){
            state.userInfo = payload;
            //console.log(state.userInfo);
            localStorage.setItem("userInfo",JSON.stringify(state.userInfo));
        }
    },
    actions:{
        async asyncChangeUserInfo({commit}){
            let GetUserInfoApiRes = await GetUserInfoApi();
          
            if(!GetUserInfoApiRes.data) return;
            let roles = GetUserInfoApiRes.data.roles;
            delete GetUserInfoApiRes.data.roles;
            commit("changeUserInfo",{
                roles:roles,
                user:GetUserInfoApiRes.data,
                
            });
            
        }
    }
}