
export function productTypeOptionsMapper(){
    return [{value:0,label:"Inverter"},{value:1,label:"PV Panel"},{value:2,label:"Battery"},{value:3,label:"EV Charger"},{value:4,label:"Design fee"},{value:5,label:"Accessory"}];
}

export function categoryOptionsMapper(){
    return [{value:0,label:"Residential"},{value:1,label:"Commercial"},{value:2,label:"Both"}];
}

export function certificationOptionsMapper(){
    return [{value:0,label:'UKCA',img:""},{value:1,label:'ENERGY STAR',img:""},{value:2,label:'RoHS',img:""},{value:3,label:'CE',img:""},]
}

export function battriesOptionsMapper(){
    return [{value:false,label:'Without Battery'},{value:true,label:"With Battery"}];
}

export function constructionModeOptionsMapper(){
    return [{value:0,label:"roof"},{value:1,label:"car port"},{value:2,label:"roof , car port"}];
}

export function orderTypeOptionsMapper(){
    return [{value:0,label:"Product order"},{value:1,label:"Design Fee order"},{value:2,label:"Solution Order"}];
    //0 产品订单，1 解决方案设计费订单， 2 解决方案订单尾款
}

export function orderStatusOptionsMapper(){
    // -1 订单取消 0 订单创建，未付款 1 已付款 2 正在出库 3 已发货，物流中 4 已收货 5 订单完毕
    return [{value:-1,label:"Order cancellation"},{value:0,label:"Unpaid payment"},{value:1,label:"Paid already"},{value:2,label:"Pending shipment"},{value:3,label:"Already shipped"},{value:4,label:"Received goods"},{value:5,label:"Order completion"}]
}

export function solutionOrderStatusOptionsMapper(){
    return [{value:-1,label:"Order cancellation"},{value:0,label:"Unpaid payment"},{value:1,label:"Design Fee has already paid"},{value:2,label:"Waiting for final payment"},{value:3,label:"The final payment has been made"},{value:4,label:"Pending shipment"},{value:5,label:"Already shipped"},{value:6,label:"Received goods"},{value:7,label:"Order completion"}]
}

export function isShelvesOptionsMapper(){
    return [{value:true,label:"On Sale"},{value:false,label:"Off Sale"}];
}

export function inStockOptionsMapper(){
    return [{value:true,label:"In Stock"},{value:false,label:"Out of Stock"}]
}