import Vue from 'vue'
import VueRouter from 'vue-router'
import { GetRoutersApi } from '@/request/api'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'mainlayout',
        component: () => import('../views/layout/MainLayout.vue'),
        redirect:"/admin/home",
        children:[
            
        ]
    },
    {
        name: 'login',
        path: '/admin/login',
        component: () => import('../views/login/LoginView.vue')
    },
    {
        name: '404',
        path: '*',
        component: ()=> import('../views/404.vue')
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to,from,next)=>{

    document.title = "Welcome to Easysolarsupply";

    const token = localStorage.getItem("authorization-token");
    //1.如果用户访问登录页面,但是token已经存在,应该跳转到首页
    if(to.path == '/admin/login' && token){
        next("/");
        return;
    }
    //2.如果用户访问非登录页面，但是没有token,要跳转到登录界面
    if(to.path != '/admin/login' && !token){
        next("/admin/login");
        return;
    }

    if(token && store.state.userMenuData.menuData.length == 0){
        let GetUserRoutersApiRes = await GetRoutersApi();
        
        let newUserMenuData = [{title:"Dashboard",path:"/admin/home",icon:"el-icon-menu",children:[]}];
        let ret = GetUserRoutersApiRes.data.map(item=>{
            if(item.isMenu == true){
                if(item.children.length){
                    return {
                        title: item.title,
                        path: item.path,
                        icon: item.icon,
                        // children: item.children.map(sitem=>{
                        //     if(sitem.isMenu == "true"){
                        //         return {
                        //             title:sitem.title,
                        //             path:sitem.path
                        //         }
                        //     } else {
                        //         return null;
                        //     }
                        // })
                        children: item.children.filter(sitem=>sitem.isMenu == true)
                    }
                } else {
                    return {
                        title: item.title,
                        path: item.path,
                        icon: item.icon,
                    }
                }
            } else {
                return null;
            }
            
            
        });
        newUserMenuData = [...newUserMenuData,...ret];
        store.commit("userMenuData/changeMenuData",newUserMenuData);
        //console.log(newUserMenuData);

        //以上生成菜单menu数据

        //一下生成路由routes数据

        let newChildrenRoutes = [
            {
                path:"/admin/home",
                meta:{titles:['Dashboard']},
                component: () => import('../views/HomeView.vue')
            },
            {
                path:"/admin/profile",
                meta:{titles:['Profile']},
                component: () => import('../views/ProfileView.vue')
            }
        ];

        //console.log(GetUserRoutersApiRes);

        //将二维转为一维
        // GetUserRoutersApiRes.data.forEach(item=>{

        //     let ret = item.children.map(sitem=>{
        //         return {
        //             path: sitem.path,
        //             component: () => import(`../views${sitem.vue_path}.vue`),
        //             meta: {titles:[item.title,sitem.title]}
        //         }
        //     });

        //     newChildrenRoutes = [...newChildrenRoutes,...ret];
        // });

        //将三维转为一维
        GetUserRoutersApiRes.data.forEach(item=>{
            let ret = item.children.map(sitem=>{
                return {
                    path: sitem.path,
                    component: () => import(`../views${sitem.vue_path}.vue`),
                    meta: {titles:[item.title,sitem.title]}
                }
            });
            newChildrenRoutes = [...newChildrenRoutes,...ret];
            if(item.children.length){
                item.children.forEach(xitem=>{
                    let zet = xitem.children.map(zitem=>{
                        return {
                            path:zitem.path,
                            component: () => import(`../views${zitem.vue_path}.vue`),
                            meta: {titles:[item.title,xitem.title,zitem.title]}
                        }
                    })
                    newChildrenRoutes = [...newChildrenRoutes,...zet];
                })
            }
            
        });
        
        newChildrenRoutes.forEach(item=>{
            router.addRoute('mainlayout',item)
        });
        next(to.path);
        return;
    }



    next();
})

export default router

