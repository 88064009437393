import Vue from 'vue'
import { Button,Input,Table,TableColumn,Form,FormItem,
    Message,Menu,MenuItem,MenuItemGroup,Submenu,Row,Col,
    Breadcrumb,BreadcrumbItem,Dropdown,DropdownItem,DropdownMenu,
    Tag,Pagination,Dialog,Select,DatePicker,TimePicker,Switch,
    CheckboxGroup,Checkbox,RadioGroup,Radio,Option,Descriptions,DescriptionsItem,
    Rate,Upload,Loading,Tree,Popconfirm,InputNumber,Link,Tabs,TabPane,Card } from 'element-ui'
       //Tinymce富文本编辑器，取代QiillEditor 1000次/月使用免费,超过1000次/月，$40/1000
import locale from 'element-ui/lib/locale';     //全局英文
import lang from 'element-ui/lib/locale/lang/en';  //全局英文

// import VueQuillEditor from 'vue-quill-editor'           //QuillEditor 富文本编辑器 弃用
// import 'quill/dist/quill.core.css'                      //QuillEditor 富文本编辑器 弃用
// import 'quill/dist/quill.snow.css'                      //QuillEditor 富文本编辑器 弃用
// import 'quill/dist/quill.bubble.css'                    //QuillEditor 富文本编辑器 弃用
// import Delta from 'quill-delta'                         //QuillEditor 富文本编辑器 弃用
// import * as Quill from 'quill'                          //QuillEditor 富文本编辑器 弃用
// import {ImageDrop} from 'quill-image-drop-module'       //QuillEditor 富文本编辑器 弃用
// Quill.register('module/imageDrop',ImageDrop)            //QuillEditor 富文本编辑器 弃用

//import ImageResize from 'quill-image-resize-module'
//Quill.register('module/imageResize',ImageResize)
locale.use(lang);   //全局英文
Vue.prototype.$message = Message
Vue.use(Button)
Vue.use(Input)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(Row)
Vue.use(Col)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Tag)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Switch)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Option)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Rate)
Vue.use(Upload)
//Vue.use(VueQuillEditor)         //QuillEditor 富文本编辑器 弃用
//Vue.use(Delta)                  //QuillEditor 富文本编辑器 弃用
Vue.use(Loading)
Vue.use(Tree)
Vue.use(Popconfirm)
Vue.use(InputNumber)
Vue.use(Link)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Card)