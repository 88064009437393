import Vue from "vue"
import { categoryOptionsMapper,productTypeOptionsMapper,constructionModeOptionsMapper, orderTypeOptionsMapper,orderStatusOptionsMapper,isShelvesOptionsMapper, inStockOptionsMapper } from "./mapper"

Vue.filter("floatFormat",value=>{
    let floatValue = parseFloat(value);
    if (isNaN(floatValue)){
        return 0;
    }
    return Math.floor(floatValue*100) / 100;
})

Vue.filter("categoryMapping",value=>{
    const list = categoryOptionsMapper();
    for (let item of list){
        if(item.value == value){
            return item.label;
        }
    }
})

Vue.filter("productTypeMapping",value=>{
    const list = productTypeOptionsMapper();
    for (let item of list){
        if(item.value == value){
            return item.label;
        }
    }
})

Vue.filter("constructionModeMapping",value=>{
    const list = constructionModeOptionsMapper();
    for (let item of list){
        if(item.value == value){
            return item.label;
        }
    }
})

Vue.filter("orderTypeMapping",value=>{
    const list = orderTypeOptionsMapper();
    for (let item of list){
        if(item.value == value){
            return item.label;
        }
    }
})

Vue.filter("orderStatusMapping",value=>{
    const list = orderStatusOptionsMapper();
    for (let item of list){
        if(item.value == value){
            return item.label;
        }
    }
})

Vue.filter("isShelvesMapping",value=>{
    const list = isShelvesOptionsMapper();
    for (let item of list){
        if(item.value == value){
            return item.label;
        }
    }
})

Vue.filter('inStockMapping',value=>{
    const list = inStockOptionsMapper();
    for (let item of list){
        if(item.value == value){
            return item.label;
        }
    }
})

Vue.filter('formatDateTime',dateString=>{
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2,'0')
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    return formattedDate;
})
    
Vue.filter('removeTrailingZeros',value=>{
    if(value){
        return parseFloat(value).toString();
    } else {
        return '';
    }
    
})